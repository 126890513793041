import {AxiosResponse} from 'axios'
import {makeAPIRequest} from '../utils/request'

class S3UploadAPI {

  async uploadImage(data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      data,
      url: `/api/getUploadUrl`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  }

  async S3UploadRequest(url: string, file: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      data: file,
      baseURL: url,
      headers: {
        'access-token': `Bearer ${jwt}`,
        'Content-Type': `multipart/form-data`,
      },
    })
    return res.data
  }
}

export default new S3UploadAPI()
