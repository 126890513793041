import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class BranchesAPI {

  async getAllBranches(entityId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `/api/entity/${entityId}/entityBranch`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data.data
  };

  async createBranch(data: any, entityId: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `/api/entity/${entityId}/entityBranch`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async updateBranch(branchId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/entityBranch/${branchId}`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async deleteBranch(branchId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'DELETE',
      url: `/api/entityBranch/${branchId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };



}

export default new BranchesAPI()