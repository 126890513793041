import {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import ClearFilterIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import './filter.css';

type ComponentProps = {
  arrayList?: any,
  filterName?: string,
  title?: string,
  saveButtonText?: string,
  clearButtonText?: string,
  onChangeFilter?: any,
  value: any,
  filterOn?: string,
  disabled?: boolean
}

const MultiSelctFilter = ({
                            arrayList,
                            filterName,
                            title,
                            saveButtonText,
                            clearButtonText,
                            onChangeFilter,
                            value,
                            filterOn = 'name',
                            disabled
                          }: ComponentProps) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [checkedArray, setCheckedArray] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * Method used for handle menu click
   */
  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  /**
   * Function is triggered when component received updated value
   */
  useEffect(() => {
    setCheckedArray(value);
  }, [value]);

  /**
   * Method used for handle the onchange event of text element
   */
  const handleChangeInput = (event: any) => {
    setSearchQuery(event.target.value);
  };

  /**
   * Method used for handle the onchange event of checkbox element
   */
  const handleChange = (event: any) => {
    const data = Object.assign([], JSON.parse(JSON.stringify(checkedArray)));
    if (event.target.checked) {
      data.push(event.target.name);
    } else {
      const findIndex = data.findIndex((item: any) => item === event.target.name);
      if (findIndex > -1) {
        data.splice(findIndex, 1);
      }
    }
    setCheckedArray(data);
  };

  /**
   * Method used apply the filter
   */
  const onApplyFilter = () => {
    const data = Object.assign([], JSON.parse(JSON.stringify(checkedArray)));
    onChangeFilter(data);
    setAnchorEl(null);
    setOpen(false);
  };

  /**
   * Method used for clear the filter value
   */
  const onClearFilter = (event: any) => {
    if (event) {
      event.stopPropagation();
    } else {
    }
    onChangeFilter([]);
    setAnchorEl(null);
    setOpen(false);
  };

  /**
   * Method used for handle click event on outside the menu
   */
  const clickAwayHandler = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  if (arrayList && arrayList.length > 0) {
    arrayList = arrayList.map((item: any) => {
      if (item.name === undefined) {
        item.name = item.label || `${item.firstName} ${item.lastName ? item.lastName : ''}`;
      }
      return item;
    });
  }

  const search = new RegExp(searchQuery, 'i'); // prepare a regex object
  const filterArray = arrayList && arrayList?.length > 0 ? arrayList?.filter((item: any) => search.test(item.name)) : [];

  return (
    <>
      {
        open && (
          <ClickAwayListener onClickAway={() => clickAwayHandler()}>
            <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition
                    className="popper-container-multiselect">
              {({TransitionProps}) => (
                <Fade {...TransitionProps}>
                  <Paper className="menu-paper">
                    <Typography variant="h6" className="margin-bottom-10">{title}</Typography>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                          className="margin-top-10 margin-bottom-10">
                      <TextField
                        size='small'
                        fullWidth
                        label="Search"
                        className="margin-top-10"
                        placeholder="Search"
                        name="searchQuery"
                        value={searchQuery}
                        onChange={handleChangeInput}
                        InputProps={{
                          startAdornment: <InputAdornment
                            position="start"><SearchIcon/></InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid container spacing={1} className="checkbox-options-container">
                      {
                        filterArray && filterArray?.length > 0
                          ? (
                            <>
                              {
                                filterArray?.map((item: any, index: any) => (
                                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
                                        key={item[filterOn]}>
                                    <FormControlLabel
                                      className="form-control-label-align"
                                      control={
                                        <Checkbox
                                          checked={!!checkedArray.find((checkedItem?) => checkedItem === item[filterOn])}
                                          className="category-checkbox"
                                          onChange={handleChange}
                                          name={item[filterOn]}
                                        />
                                      }
                                      label={item.name || item.label || item.firstName + ' ' + item.lastName}
                                    />
                                  </Grid>
                                ))
                              }
                            </>
                          )
                          : (
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography variant='body1' style={{margin: '30px 0px', textAlign: 'center'}}>
                                No data available
                              </Typography>
                            </Grid>
                          )
                      }
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Stack direction="row" spacing={1.5} mt={1} justifyContent="flex-end">
                          <Button
                            variant="contained"
                            color="inherit"
                            onClick={(e) => onClearFilter(e)}
                          >
                            {clearButtonText}
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onApplyFilter()}
                          >
                            {saveButtonText}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>
        )
      }
      <Button
        className="margin-right-10 margin-bottom-10"
        disabled={disabled}
        color='primary'
        variant={`${value?.length > 0 ? "contained" : "outlined"}`}
        onClick={handleClickMenu}
        size="small"
      >
        {`${filterName}: ${value?.length > 0 && value?.length !== arrayList?.length ? '(' + value?.length + ')' : 'All'}`}
        {value?.length > 0 && <ClearFilterIcon className="clear-filter-icon" onClick={(e) => onClearFilter(e)}/>}
      </Button>
    </>
  )
};


export default MultiSelctFilter;
