export const EXPERTISE_LIST = [
    "Beginner level",
    "Intermediate level",
    "Qualified level",
];

export const LANGUAGE_LIST = [
    "English",
    "Swahili",
    "French",
    "Portuguese",
];

export const COUNTRY_LIST = [
    "Angola",
    "Botswana",
    "Burundi",
    "Ghana",
    "Kenya",
    "Lesotho",
    "Malawi",
    "Mozambique",
    "Namibia",
    "South Africa",
    "Swaziland",
    "Tanzania",
    "Uganda",
    "Republic of Congo",
    "Rwanda",
    "Zambia",
    "Zimbabwe",
];

export const INFORMATION_TYPE_LIST = [
    "Job post",
    "Aid information",
];

export const SIDE_MENU = [
    { label: 'Signer', routeName: 'interpreters' },
    { label: 'Guides', routeName: 'guides' },
    { label: 'Content Management', routeName: 'contentManagement' },
    { label: 'Contact Management', routeName: 'contactManagement' },
    {
        label: 'Phonebook Entities',
        routeName: 'phoneBook',
        submenu: [
          { label: 'Category ', routeName: 'entityCategories' },
          { label: 'Entity', routeName: 'entityManagement' },
        ],
      },
]