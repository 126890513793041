import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class ContentAPI {

  async getAllPosts(params: any): Promise<any> {
    const jwt = localStorage.getItem('access-token');
    const offset = params.page * params.pageSize;
    const limit = params.pageSize;
    const searchData = { includeExpired: true };

    const queryParams = new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        searchData: JSON.stringify(searchData),
    });

    const res: AxiosResponse = await makeAPIRequest({
        method: 'GET',
        url: `/api/contentManagement?${queryParams}`,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });

    return res.data.data;
}

  async createPost(data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `/api/contentManagement`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async updatePost(postId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/contentManagement/${postId}`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async enabledDisabledPost(postId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/contentManagement/${postId}/activateOrDeactivateContent`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async removePost(postId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'DELETE',
      url: `/api/contentManagement/${postId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

}

export default new ContentAPI()