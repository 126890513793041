import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useForm from "../../../hooks/useForm";
import { useAuth } from '../../../hooks/check-auth';
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import VideoAPI from "../../../apis/video.api";


type FormData = {
    enable: boolean,
    title: string
    link: string,

};

type ComponentProps = {
    contactId?: string,
    initialValue?: FormData,
    onSuccess?: () => void
    onCancel?: () => void
    onClose?: () => void,
    postId?: string;
    setOpenVideo?: any,
    entityId?: any,
    selectedRow?: any
    videoId?: any,
    isRefresh?: any,
    setIsRefresh?:any,
    setReload?:any,
    getAllVideos?:any
    paginationModel?:any

};

const AddVideoModal = (props: ComponentProps) => {
    
    const { onShowSnackbar } = useAuth();
    const defaultValues: FormData = {
        title: '',
        enable: true,
        link: ""
    };


    const { formValues, setFormValues, handleInputChange, errors, setErrors, handleReset } = useForm({ ...defaultValues });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        if (props.initialValue) {
            setFormValues(props.initialValue)
        }

    }, [props.initialValue])

    const isURLValid = (url: any) => {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlRegex.test(url);
    };
    const validate = () => {
        const formErrors: FormData = {} as FormData
        formErrors.title = formValues?.title.trim() === '' ? 'Title is required' : '';
        formErrors.link = formValues?.link.trim() === '' ? 'URL is required' : '';

        if (formValues?.link && !isURLValid(formValues?.link.trim())) {
            formErrors.link = 'Invalid URL';
        }

        setErrors({
            ...formErrors
        })

        return !Object.values(formErrors).some(val => val !== '')
    };

    const handleCancel = () => {
        props.onCancel?.();

    };

    const createVideo = () => {
        setIsLoading(true);
        let formData = Object.assign({}, JSON.parse(JSON.stringify(formValues)));
        VideoAPI.createVideo(formData, props.entityId)
            .then((res) => {
                props?.onSuccess?.()
                props?.getAllVideos(props.paginationModel)
                props?.setIsRefresh(true)
                
                props.setReload((prevReload: any) => !prevReload);
                onShowSnackbar("Video added successfully", "success");
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateVideo = (id: string, data: any) => {
        const formData = {
            title: data.title,
            enable: data.enable,
            link: data.link,
        };
        VideoAPI
            .updateVideo(id, formData)
            .then((res) => {
                props?.onSuccess?.()
                props?.getAllVideos(props.paginationModel)
                onShowSnackbar("Video updated successfully", "success");
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault()

        if (validate()) {
            if (props.videoId) {
                updateVideo(props.videoId, formValues)

            } else {
                createVideo()
            }
        }
    };


    const handleCheckboxChange = (elementName: string, value: boolean) => {
        setFormValues({
            ...formValues,
            [elementName]: value,
        });
    };
    const today: string = new Date().toISOString().split('T')[0];


    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing="16">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        required
                        fullWidth
                        id="name-input"
                        name="title"
                        label="Video Title"
                        type="text"
                        style={{ marginTop: '20px' }}
                        value={formValues.title}
                        onChange={handleInputChange}
                        error={!!errors.title}
                        helperText={errors.title}
                        autoFocus={true}

                    />
                </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        required
                        fullWidth
                        id="name-input"
                        name="link"
                        label="Video URL"
                        type="text"
                        value={formValues.link}
                        onChange={handleInputChange}
                        error={!!errors.link}
                        helperText={errors.link}
                        autoFocus={true}

                    />

                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px', marginTop: '-30px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formValues && formValues.enable}
                                    onChange={(e) => handleCheckboxChange("enable", e.target.checked)}
                                    style={{ fontSize: '16px',  marginTop: '25px' }}
                                    color="primary"
                                />
                            }
                            label=""

                            style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
                        />
                        <Typography style={{ fontSize: '16px', marginLeft: '-10px', marginTop: '40px' }} variant="body1">
                            Active
                        </Typography>
                    </div>
                    {errors.enable && <Typography style={{ color: 'red' }}>{errors.enable}</Typography>}
                </Grid>
               

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2} style={{ paddingTop: '15px' }}>
                        <Grid item xs={12}>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" style={{ marginLeft: '10px', float: 'right' }}>
                                {props.selectedRow ? "Save" : "Add"}
                            </Button>
                            <Button disabled={isLoading} variant="outlined" color="primary" type="button" onClick={handleCancel} style={{ marginLeft: '10px', float: 'right' }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </form >
    );
};

export default AddVideoModal;