import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faLandmark, faAddressCard } from '@fortawesome/free-solid-svg-icons';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import FeedIcon from '@mui/icons-material/Feed';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useAuth } from '../../hooks';
import LOGO from '../../assets/Logo.png';
import { useNavigate } from 'react-router-dom';
import { SIDE_MENU } from '../../utils/constants';
import { Collapse, Typography } from '@mui/material';
import React from 'react';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface SidebarProps {
    open: boolean;
    setOpen: (val: boolean) => void;
}

const Sidebar = ({ open, setOpen }: SidebarProps) => {
    const { signOut } = useAuth();
    const navigate = useNavigate();

    const [openNested, setOpenNested] = React.useState(false);
    const currentRoute = window.location.pathname;

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const toggleNested = () => {
        setOpenNested(!openNested);
    };

    const hasSubmenu = (item: any) => {
        return item.submenu && item.submenu.length > 0;
    };

    const handleItemClick = (item: any) => {
        if (hasSubmenu(item)) {
            toggleNested();
        } else {
            navigate(`/${item.routeName}`);
        }
    };

    const iconRender = (routeName: string) => {
        switch (routeName) {
            case 'interpreters':
                return <SignLanguageIcon />;
            case 'guides':
                return <AccessibilityIcon />;
            case 'contentManagement':
                return <FeedIcon />;
            case 'contactManagement':
                return <ContactPhoneIcon />;
            case 'phoneBook':
                return <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: '1.3em' }}/>;
            case 'entityCategories':
                return <FontAwesomeIcon icon={faLayerGroup} />;
            case 'entityManagement':
                return <FontAwesomeIcon icon={faLandmark} />;
            default:
                break;
        }
    };

    const renderSubMenu = (submenu: any) => {
        return (
            <List component="div" disablePadding>
                {submenu.map((item: any) => (
                    <ListItemButton
                        key={item.routeName}
                        onClick={() => navigate(`/${item.routeName}`)}
                        selected={currentRoute.startsWith(`/${item.routeName}`)}
                        style={{ paddingLeft: 32 }}
                    >
                        <ListItemIcon>{iconRender(item.routeName)}</ListItemIcon>
                        <ListItemText primary={<Typography color="inherit">{item.label}</Typography>} />
                    </ListItemButton>
                ))}
            </List>
        );
    };

    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <img className='logo-image' src={LOGO} alt='App LOGO' />
                <IconButton style={{ float: 'right' }} onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                {SIDE_MENU.map((item: any, index: number) => (
                    <React.Fragment key={item.routeName}>
                        {item.submenu ? (
                            <ListItemButton
                                onClick={() => handleItemClick(item)}
                                selected={
                                    currentRoute.startsWith(`/${item.routeName}`) ||
                                    (item.submenu && item.submenu.some((subitem: { routeName: any; }) => currentRoute.startsWith(`/${subitem.routeName}`)))
                                }
                                divider={index !== SIDE_MENU.length - 1}
                            >
                                <Tooltip title={item.label}>
                                    <ListItemIcon>{iconRender(item.routeName)}</ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                    primary={<Typography color="inherit">{item.label}</Typography>}
                                />
                                {openNested ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        ) : (
                            <ListItemButton
                                onClick={() => handleItemClick(item)}
                                selected={currentRoute === `/${item.routeName}`}

                            >
                                <Tooltip title={item.label}>
                                    <ListItemIcon>{iconRender(item.routeName)}</ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={<Typography color="inherit">{item.label}</Typography>} />
                            </ListItemButton>
                        )}
                        {item.submenu && (
                            <Collapse in={openNested && open} timeout="auto" unmountOnExit>
                                <div>{renderSubMenu(item.submenu)}</div>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
                <ListItemButton onClick={() => signOut()} selected={currentRoute === '/logout'}>
                    <Tooltip title="Logout">
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </Drawer>
    );
};

export default Sidebar;
