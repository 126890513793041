import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class SignerAPI {

    async getAllSigners(params: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const offset = params.page * params.pageSize;
        const limit = params.pageSize;
        const searchData={roleName:"Sign Interpreter"}
        const res: AxiosResponse = await makeAPIRequest({
          method: 'GET',
          url: `/api/users?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data.data
    };

    async createSigner(data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'POST',
          url: `/api/users/invite`,
          data,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
    };

    async updateSigner(userId: string, data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'PUT',
          url: `/api/users/${userId}`,
          data,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
    };

    async enabledDisabledSigner(userId: string, data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'PUT',
          url: `/api/users/${userId}/activateOrDeactivateAccount`,
          data,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
    };

}

export default new SignerAPI()