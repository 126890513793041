
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useAuth } from '../../hooks/check-auth';
import { useNavigate } from 'react-router-dom';
import EntityAPI from '../../apis/entity.api';


const EntityManagementTable = (props: any) => {
    const { onShowSnackbar } = useAuth();
    const [userData, setUserData] = useState<GridRowsProp>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const ref = props.reference;
    const [openEnableDisableModal, setOpenEnableDisableModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [openAddVideoModal, setOpenAddVideoModal] = useState<boolean>(false);

    const navigateToNewRoute = (id: string) => {
        navigate(`/entityDetail/${id}`);
    };
    const navigateToNewEntityBranchRoute = (id: string) => {
        navigate(`/branches/${id}`);
    };
    const handleOpenEnableDisableModal = (row: any) => {
        setSelectedRow(row);
        setOpenEnableDisableModal(true);
    };

    const handleCloseEnableDisableModal = () => {
        setSelectedRow(null);
        setOpenEnableDisableModal(false);
    };
    useEffect(() => {
        if (props.isRefresh) {
            props.getAllEntityManagement(props.paginationModel)
        }

    }, [props.isRefresh])



    const columns: GridColDef[] = [
        {
            field: 'companyName',
            headerName: 'Company Name',
            flex: 1,
            sortable: false,
            // filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        {params.row.companyName && (
                            <Tooltip title={` ${params.row.companyName}`} className="flexfull displayEllipsis">
                                <Typography variant="body1">{params.row.companyName}</Typography>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'contactNumber',
            headerName: 'Contact Number',
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        {params.row.contactNumber && (
                            <Tooltip title={`${params.row.contactNumber}`} className="flexfull displayEllipsis">
                                <Typography variant="body1">{params.row.contactNumber}</Typography>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'bannerImage',
            headerName: 'Banner Image',
            maxWidth: 130,
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => {
               
                return (
                    <div>
                        {params.row.Category.icon && (
                            <Tooltip title={params.row.Category.icon}>
                                <img
                                    src={params.row.bannerImage.url}
                                    alt=""
                                    style={{ width: '50px', height: '50px' }}  
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        {params.row.email && (
                            <Tooltip title={` ${params.row.email}`} className="flexfull displayEllipsis">
                                <Typography variant="body1">{params.row.email}</Typography>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        {params.row.address && (
                            <Tooltip title={` ${params.row.address}`} className="flexfull displayEllipsis">
                                <Typography variant="body1">{params.row.address}</Typography>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'websiteLink',
            headerName: 'Website Link',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        {params.row.websiteLink && (
                            <Tooltip title={` ${params.row.websiteLink}`} className="flexfull displayEllipsis">
                                <Typography variant="body1">{params.row.websiteLink}</Typography>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        {params.row.description && (
                            <Tooltip title={` ${params.row.description}`} className="flexfull displayEllipsis">
                                <Typography variant="body1">{params.row.description}</Typography>
                            </Tooltip>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'name', headerName: 'Category', flex: 1, sortable: false,
            renderCell: (params) => {
                return (
                    <div className="flexfull displayEllipsis">
                        <Tooltip title={params.row.Category.name} className="flexfull displayEllipsis">
                            <Typography variant="body1">{params.row.Category.name}</Typography>
                        </Tooltip>
                    </div>
                );
            }
        },

        {
            field: 'enabled', headerName: 'Action', flex: 1, maxWidth: 120,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip
                            title={params.row.enable ? "Enable" : "Disable"}
                        >
                            <Switch
                                checked={params.row.enable}
                                size="small"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setOpenEnableDisableModal(true);
                                    setSelectedRow(params.row);
                                    handleOpenEnableDisableModal(params.row);

                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Video">
                            <AddPhotoAlternateIcon
                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    navigateToNewRoute(params.row.id);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Branchs">
                            <AddCircleIcon
                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    navigateToNewEntityBranchRoute(params.row.id);
                                }}
                            />
                        </Tooltip>
                    </>
                )
            }
        },
    ];
    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rowCount={props.totalCount}
                        apiRef={ref}
                        rows={props.userData}
                        columns={columns}
                        rowHeight={48}
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'}
                        columnHeaderHeight={64}
                        autoHeight
                        disableColumnMenu
                        disableColumnSelector
                        pageSizeOptions={[10, 25, 50, 100]}
                        paginationModel={props.paginationModel}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        onRowClick={(params: any, event: any) => {
                            props.onEditUser(params.row)
                        }}
                        onPaginationModelChange={(model, details) => {
                            let data = Object.assign({}, JSON.parse(JSON.stringify(model)));
                            data.searchData = props.paginationModel.searchData;
                            props.setPaginationModel(data);
                        }}
                        paginationMode="server"
                        showColumnVerticalBorder={true}
                        showCellVerticalBorder={true}
                        loading={isLoading}
                    />
                    {openAddVideoModal && selectedRow && (
                        <Dialog
                            maxWidth="md"
                            open={openAddVideoModal}
                        >
                            <DialogTitle>
                                {selectedRow.enabled ? "Update Video" : "Add Video"}
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography variant='body1'>
                                    {`Are you sure you want to ${selectedRow.enabled ? "disable" : "enable"} Video?`}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size='small' variant='outlined'
                                    onClick={() => {
                                        setOpenEnableDisableModal(false);
                                        setSelectedRow(null);
                                    }}
                                    disabled={isLoading}
                                >
                                    No
                                </Button>
                                <Button
                                    size='small' variant='contained'
                                    onClick={() => {

                                    }}
                                    disabled={isLoading}
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {openEnableDisableModal && selectedRow && (
                        <Dialog
                            maxWidth="md"
                            open={openEnableDisableModal}
                            onClose={handleCloseEnableDisableModal}
                        >
                            <DialogTitle>
                                {selectedRow.enable ? "Disable Entity" : "Enable Entity"}
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography variant='body1'>
                                    {`Are you sure you want to ${selectedRow.enable ? "disable" : "enable"} this entity?`}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size='small' variant='outlined'
                                    onClick={handleCloseEnableDisableModal}
                                    disabled={isLoading}
                                >
                                    No
                                </Button>
                                <Button
                                    size='small' variant='contained'
                                    onClick={() => {
                                        handleCloseEnableDisableModal();
                                    }}
                                    disabled={isLoading}
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {openEnableDisableModal && selectedRow && (
                        <Dialog
                            maxWidth="md"
                            open={openEnableDisableModal}
                            onClose={handleCloseEnableDisableModal}
                        >
                            <DialogTitle>
                                {selectedRow.enable ? "Disable Entity" : "Enable Entity"}
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography variant='body1'>
                                    {`Are you sure you want to ${selectedRow.enable ? "disable" : "enable"} this entity?`}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size='small' variant='outlined'
                                    onClick={handleCloseEnableDisableModal}
                                    disabled={isLoading}
                                >
                                    No
                                </Button>
                                <Button
                                    size='small' variant='contained'
                                    onClick={() => {
                                        const updateEntity = {
                                            contactNumber: selectedRow.contactNumber,
                                            websiteLink: selectedRow.url,
                                            companyName: selectedRow.companyName,
                                            address: selectedRow.address,
                                            CategoryId: selectedRow.Category.id,
                                            description: selectedRow.description,
                                            email: selectedRow.icon,
                                            enable: !selectedRow.enable,
                                            bannerImage: selectedRow.bannerImage ? selectedRow.bannerImage.path : undefined,
                                        };
                                       
                                        EntityAPI.updateEntity(selectedRow.id, updateEntity)
                                            .then(() => {
                                                setUserData((prevData) =>
                                                    prevData.map((row) =>
                                                        row.id === selectedRow.id ? { ...row, enable: !row.enable } : row
                                                    )
                                                );
                                                props?.onSuccess?.();

                                                onShowSnackbar("Entity status changed successfully", "success");
                                            })
                                            .catch((error) => {
                                                console.error('Error updating entity:', error);
                                            })
                                            .finally(() => {
                                                handleCloseEnableDisableModal();
                                                // props?.setIsRefresh(true);
                                            });
                                    }}
                                    disabled={isLoading}
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}




                </div>
            </div>

        </>
    );
};

export default EntityManagementTable;
