import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class CategoryAPI {

    async getAllCategory(params?: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const offset = params && params.page !== undefined ? params.page * params.pageSize : "";
        const limit = params && params.pageSize !== undefined ? params.pageSize : "";
        const res: AxiosResponse = await makeAPIRequest({
            method: 'GET',
            url: `/api/categories?offset=${offset}&limit=${limit}`,
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        })
        return res.data.data
    };

    async createCategory(data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
            method: 'POST',
            url: `/api/category`,
            data,
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        })
        return res.data
    };

    async updateCategory(categoryId: string, data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
            method: 'PUT',
            url: `/api/category/${categoryId}`,
            data,
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        })
        return res.data
    };
    async S3UploadRequest(url: string, file: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'PUT',
          data: file,
          baseURL: url,
          headers: {
            'access-token': `Bearer ${jwt}`,
            'Content-Type': `multipart/form-data`,
          },
        })
        return res.data
      }
      async getUploadSignURL(categoryId: string, docType: string, data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'POST',
          data,
          url: `/api/organizations/${categoryId}/getUploadSignUrl/${docType}`,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
      }

}

export default new CategoryAPI()