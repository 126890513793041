import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const styleHeader = {
  bgcolor: 'primary.main', color: '#fff',
}

const ModalComponent = ({ children, title, open, onClose, fullWidth = false }: any) => {
  return (
    <div>
      <Dialog
        fullScreen={fullWidth}
        fullWidth={true}
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="md"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle sx={styleHeader} id="scroll-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent dividers={true}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ModalComponent
