export const isNameValid = (value: string) => {
    return /^[a-zA-Z]+[a-zA-Z '.-]*$/.test(value)
};
export const validateMobileNumber = (val: string) => {
    return /^(0|91)?[6-9][0-9]{5,14}$/.test(val)
}

export const validateEmailAddress = (val: string) => {
    return /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(val)
}

export const containsOnlyNumbers = (val: string) => {
    return /^\d+$/.test(val)
}

// Password complexity - minimum eight characters, at least one letter and one number
export const validatePasswordComplexity = (val: string) => {
    return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(val)
}

export const validateLicenseNo = (val: string) => {
    return /^([A-Z]{2})(\d{2}|\d{3})[a-zA-Z]{0,1}(\d{4})(\d{7})$/.test(val);
}