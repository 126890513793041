import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useForm from "../../../hooks/useForm";
import { useAuth } from '../../../hooks/check-auth';
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import BranchesAPI from "../../../apis/branches.api";
import { validateEmailAddress, validateMobileNumber } from "../../../utils/formating/string";

type FormData = {
    name: string,
    email: string
    contactNumber: string,
    address: string,
    website: string,
};

type ComponentProps = {
    entityId?: any,
    branchId?: any,
    initialValue?: FormData,
    onCancel?: () => void,
    onSuccess?: () => void,
    getAllBranches?:any,
    
};

const AddBranchModal = (props: ComponentProps) => {

    const { onShowSnackbar } = useAuth();

    const defaultValues: FormData = {
        name: '',
        email: '',
        contactNumber: '',
        address: '',
        website: '',
    };

    const { formValues, setFormValues, handleInputChange, errors, setErrors, handleReset } = useForm({ ...defaultValues });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (props.initialValue) {
            setFormValues(props.initialValue)
        }

    }, [props.initialValue]);

    const isURLValid = (url: any) => {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlRegex.test(url);
    };

    const validate = () => {
        const formErrors: FormData = {} as FormData
        formErrors.name = formValues?.name.trim() === '' ? 'Name is required' : '';
        formErrors.address = formValues?.address.trim() === '' ? 'Address is required' : '';
        formErrors.email = formValues.email?!validateEmailAddress(formValues.email) ? 'Please enter a valid email' : '':"";
        formErrors.contactNumber = formValues.contactNumber ? !validateMobileNumber(formValues.contactNumber) ? 'Please enter a valid contact number' : '' : "";
        if (formValues?.website && !isURLValid(formValues?.website.trim())) {
            formErrors.website = 'Invalid website url';
        }

        setErrors({
            ...formErrors
        })

        return !Object.values(formErrors).some(val => val !== '')
    };

    const handleCancel = () => {
        props.onCancel?.();
    };

    const createBranch = () => {
        setIsLoading(true);
        let formData = Object.assign({}, JSON.parse(JSON.stringify(formValues)));
        BranchesAPI.createBranch(formData, props.entityId)
            .then((res) => {
                props?.onSuccess?.()
                props?.getAllBranches()
                
                onShowSnackbar("Branch added successfully", "success");
                handleReset();
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateBranch = (id: string, data: any) => {
        const formData = {
            name: data.name,
            email: data.email,
            contactNumber: data.contactNumber,
            address: data.address,
            website: data.website,
        };
        BranchesAPI
            .updateBranch(id, formData)
            .then((res) => {
                props?.onSuccess?.()
                props?.getAllBranches()
                onShowSnackbar("Branch updated successfully", "success");
                handleReset();
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault()

        if (validate()) {
            if (props.branchId) {
                updateBranch(props.branchId, formValues)

            } else {
                createBranch()
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing="16">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        required
                        fullWidth
                        id="name-input"
                        name="name"
                        label="Name"
                        type="text"
                        style={{ marginTop: '20px' }}
                        value={formValues.name}
                        onChange={handleInputChange}
                        error={!!errors.name}
                        helperText={errors.name}
                        autoFocus={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        id="email-input"
                        name="email"
                        label="Email"
                        type="text"
                        value={formValues.email}
                        onChange={handleInputChange}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        id="contactNumber-input"
                        name="contactNumber"
                        label="Contact Number"
                        type="text"
                        value={formValues.contactNumber}
                        onChange={handleInputChange}
                        inputProps={{
                            maxLength: 15
                        }}
                        error={!!errors.contactNumber}
                        helperText={errors.contactNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        required
                        fullWidth
                        id="address-input"
                        name="address"
                        label="Address"
                        type="text"
                        value={formValues.address}
                        onChange={handleInputChange}
                        error={!!errors.address}
                        helperText={errors.address}
                        multiline
                        minRows={2}
                        maxRows={5}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        id="website-input"
                        name="website"
                        label="Website"
                        type="text"
                        value={formValues.website}
                        onChange={handleInputChange}
                        error={!!errors.website}
                        helperText={errors.website}
                        autoFocus={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2} style={{ paddingTop: '15px' }}>
                        <Grid item xs={12}>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" style={{ marginLeft: '10px', float: 'right' }}>
                                {props.branchId ? "Save" : "Add"}
                            </Button>
                            <Button disabled={isLoading} variant="outlined" color="primary" type="button" onClick={handleCancel} style={{ marginLeft: '10px', float: 'right' }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )

};

export default AddBranchModal;