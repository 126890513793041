import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class ContactAPI {

    async getAllContacts(params: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const offset = params.page * params.pageSize;
        const limit = params.pageSize;
        const searchData={}
        const res: AxiosResponse = await makeAPIRequest({
          method: 'GET',
          url: `/api/contacts?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(searchData)}`,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data.data
    };

    async createContact(data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'POST',
          url: `/api/contacts/create`,
          data,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
    };

    async updateContact(contactId: string, data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'PUT',
          url: `/api/contacts/${contactId}`,
          data,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
    };

    async enabledDisabledContact(contactId: string, data: any): Promise<any> {
        const jwt = localStorage.getItem('access-token')
        const res: AxiosResponse = await makeAPIRequest({
          method: 'PUT',
          url: `/api/contacts/${contactId}/activateOrDeactivateContact`,
          data,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        return res.data
    };

    async removeContact(contactId: string): Promise<any> {
      const jwt = localStorage.getItem('access-token')
      const res: AxiosResponse = await makeAPIRequest({
        method: 'DELETE',
        url: `/api/contacts/${contactId}`,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      return res.data
  };

}

export default new ContactAPI()