import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks'

import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const RequireAuth = () => {
  const { isLoading, isAuthenticated, isSessionExpired, isSystemGeneratedPassword, error, signOut } = useAuth()

  if (!isLoading && !isAuthenticated) {
    return <Navigate to={{ pathname: '/signIn' }} state={{ from: '' }} />
  }else if(isSystemGeneratedPassword){
    return <Navigate to={{ pathname: '/resetPassword' }} state={{ from: '' }} />
  }else if(isSessionExpired){
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={isSessionExpired} fullWidth>
        <MuiDialogTitle style={{paddingLeft: '20px'}}>
            <Typography variant="h6">Session Expired</Typography>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <Typography variant="body1">{error}</Typography>
        </MuiDialogContent>
        <MuiDialogActions>
            <Button autoFocus onClick={signOut} variant="contained" style={{marginRight: '25px'}} >
                Ok
            </Button>
        </MuiDialogActions>
      </Dialog>
    )
  }

  return <Outlet />
}

export default RequireAuth
