import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class EntityAPI {

  async getAllEntity(params: any): Promise<any> {
    const jwt = localStorage.getItem('access-token');
    const offset = params.page * params.pageSize;
    const limit = params.pageSize;
    const searchCompany =  params.searchData.searchTerm;
    const categoryIds= params.searchData.selectedValue.join(",");;


    const res: AxiosResponse = await makeAPIRequest({
        method: 'GET',
        url: `/api/entities?offset=${offset}&limit=${limit}&companyName=${searchCompany}&category=${categoryIds}`,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });

    return res.data.data;
};

  async createEntity(data: any, categoryId: string): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `/api/category/${categoryId}/entity`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async updateEntity(entityId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/entity/${entityId}`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async getEntityById(entityId: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `/api/entity/${entityId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  }
  async enabledDisabledEntity(entityId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/contacts/${entityId}/activateOrDeactivateContact`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };


}

export default new EntityAPI()