import { AxiosResponse } from 'axios'
import { makeAPIRequest } from '../utils/request';

class VideoAPI {

  async getAllVideos(params?: any,paginationModel?:any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const offset = paginationModel.page * paginationModel.pageSize;
    const limit = paginationModel.pageSize;
    const entityId = params

    const res: AxiosResponse = await makeAPIRequest({
      method: 'GET',
      url: `/api/videos?offset=${offset}&limit=${limit}&entity=${entityId}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data.data
  };

  async createVideo(data: any, entityId: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'POST',
      url: `/api/entity/${entityId}/video`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async updateVideo(videoId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/video/${videoId}`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };

  async enabledDisabledEntity(entityId: string, data: any): Promise<any> {
    const jwt = localStorage.getItem('access-token')
    const res: AxiosResponse = await makeAPIRequest({
      method: 'PUT',
      url: `/api/contacts/${entityId}/activateOrDeactivateContact`,
      data,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    return res.data
  };



}

export default new VideoAPI()