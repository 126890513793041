import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import useForm from "../../../hooks/useForm";
import { contactAPI } from '../../../apis';
import { useAuth } from '../../../hooks/check-auth';
import { Avatar, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Link, Switch, Tooltip, Typography } from "@mui/material";
import AddVideoModal from "./addVideoModal";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import VideoAPI from '../../../apis/video.api'
import { useParams } from "react-router-dom";
import EntityApi from "../../../apis/entity.api";

type FormData = {
    enable: boolean,
    title: string
    link: string,
};

type ComponentProps = {
    contactId?: string,
    initialValue?: FormData,
    onCancel?: () => void
    onClose?: () => void,
    postId?: string;
    onSuccess?: () => void;
    setIsRefresh?: () => any;
    handleSuccess?: () => void;
};

const AddVideo = (props: ComponentProps) => {
    const { onShowSnackbar } = useAuth();
    const defaultValues: FormData = {
        title: '',
        enable: true,
        link: ""
    };

    const { formValues, setFormValues, handleInputChange, errors, setErrors, handleReset } = useForm({ ...defaultValues });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [bannerImageError, setBannerImageError] = useState<string | null>(null);
    const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState(0);
    const [videoData, setVideoData] = useState<GridRowsProp>([]);
    const [entityData, setEntityData] = useState<any | null>(null);
    const [openEnableDisableModal, setOpenEnableDisableModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const [paginationModel, setPaginationModel] = useState({
        page: 0, pageSize: 10,
        searchData: {
            roles: [],
        }
    })

    const { id } = useParams();

    useEffect(() => {
        if (props.initialValue) {
            setFormValues(props.initialValue);
        }
    }, [props.initialValue]);

    const validate = () => {
        const formErrors: FormData = {} as FormData
        formErrors.title = formValues.title.trim() === '' ? 'Title is required' : "";


        setErrors({
            ...formErrors
        })

        return !Object.values(formErrors).some(val => val !== '')
    };

    const handleCancel = () => {
        handleReset()
        props.onCancel?.()
    };
    const getAllVideos = (paginationModel?: any) => {
        setIsLoading(true);
        VideoAPI.getAllVideos(id, paginationModel)
            .then((res) => {
                setTotalCount(res.count);
                setVideoData(res.rows);
            })
            .catch((err) => console.log("err=====>", err.message))  // Fix the typo here (err.messsage to err.message)
            .finally(() => setIsLoading(false));
    };


    const updateVideo = (id: string, data: any) => {
        setIsLoading(true);
        let formData = Object.assign({}, JSON.parse(JSON.stringify(data)));
        VideoAPI
            .updateVideo(id, formData)
            .then((res) => {
                props?.onSuccess?.()
                getAllVideos()
                onShowSnackbar("Video status changed successfully", "success");
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setIsLoading(false);
                getAllVideos(paginationModel);
            });
    };

    useEffect(() => {
        getAllVideos(paginationModel);
    }, [reload]);

    useEffect(() => {
        getAllVideos(paginationModel);
    }, [paginationModel]);

    const getEntityById = () => {
        setIsLoading(true);
        EntityApi.getEntityById(id)
            .then((res) => {
                setEntityData(res.data);
            })
            .catch((err) => console.log("err=====>", err.message))
            .finally(() => setIsLoading(false));
    }
    useEffect(() => {
        getEntityById();
    }, [id]);


    const handleConfirmationYes = () => {
        updateVideo(selectedRow.id, {
            enable: !selectedRow.enable,
            title: selectedRow.title,
            link: selectedRow.link
        });

        setConfirmationDialogOpen(false);
        setSelectedRow(null);
    };


    const columns: GridColDef[] = [
        {
            field: 'title', maxWidth: 420, sortable: false,
            filterable: false, headerName: 'Video Title', flex: 1
        },


        {
            field: "url",
            headerName: "Video Link",
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {


                return (
                    <div>
                        <Link href={params.row.link} target="_blank" rel="noopener noreferrer">
                            {params.row.link}
                        </Link>
                    </div>
                );
            },
        },
        {
            field: 'enabled',
            headerName: 'Action',
            flex: 1,
            maxWidth: 120,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title={params.row.enable ? 'Enable' : 'Disable'}>
                            <Switch
                                checked={params.row.enable}
                                size="small"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setOpenEnableDisableModal(true);
                                    setSelectedRow(params.row);
                                    setConfirmationDialogOpen(true);
                    
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },


    ];

    const handleAddVideoClick = () => {
        setOpenVideoModal(true);


    };
    return (

        <Grid container spacing="16">

            <Button color="primary" size="medium" variant="contained" style={{ marginLeft: '90%', marginTop: '20px' }} onClick={handleAddVideoClick}>
                Add Video
            </Button>
            {openVideoModal &&
                <Dialog maxWidth="md" open={openVideoModal} onClose={handleCancel}>
                    <DialogTitle>{selectedRow ? 'UPDATE VIDEO' : 'ADD VIDEO'}</DialogTitle>
                    <DialogContent>

                        <AddVideoModal
                            entityId={id}
                            videoId={selectedRow ? selectedRow['id'] || "" : ""}
                            initialValue={{
                                title: selectedRow ? selectedRow["title"] || "" : "",
                                enable: selectedRow && selectedRow["enable"] !== undefined ? selectedRow["enable"] : true,
                                link: selectedRow ? selectedRow["link"] || "" : "",

                            }}
                            onCancel={() => {
                                setOpenVideoModal(false)
                                setSelectedRow(null);
                            }}

                            onSuccess={() => {
                                setOpenVideoModal(false);
                                props.onClose?.();
                                props.onSuccess?.();
                                props.setIsRefresh?.()

                            }}
                            selectedRow={selectedRow}
                            setReload={setReload}
                            getAllVideos={getAllVideos}
                            paginationModel={paginationModel}
                            
                        />
                    </DialogContent>
                </Dialog>
            }

            {confirmationDialogOpen && selectedRow && (
                <Dialog maxWidth="md" open={confirmationDialogOpen}>
                    <DialogTitle>
                        {selectedRow.enable ? "Disable Video" : "Enable Video"}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant='body1'>
                            {`Are you sure you want to ${selectedRow.enable ? "disable" : "enable"} this video?`}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size='small' variant='outlined'
                            onClick={() => {
                                setConfirmationDialogOpen(false);
                                setSelectedRow(null);
                            }}
                            disabled={isLoading}
                        >
                            No
                        </Button>
                        <Button
                            size='small' variant='contained'
                            onClick={handleConfirmationYes}
                            disabled={isLoading}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Card style={{ marginLeft: '15px', marginTop: '20px', width: '100%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <div>
                                <img
                                    src={entityData?.bannerImage.url}
                                    alt="Banner Image"
                                    style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>

                                    <Tooltip title={` ${entityData?.companyName || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Company Name:</strong> {entityData?.companyName || ''}
                                        </Typography>
                                    </Tooltip>

                                    <Tooltip title={` ${entityData?.email || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px', }} variant="body1">
                                            <strong>Email:</strong> {entityData?.email || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.websiteLink || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Website Link:</strong> {entityData?.websiteLink || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.description || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Description:</strong> {entityData?.description || ''}
                                        </Typography>
                                    </Tooltip>

                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>

                                    <Tooltip title={` ${entityData?.contactNumber || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Contact Number:</strong> {entityData?.contactNumber || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.address || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Address:</strong> {entityData?.address || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.Category?.name || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Category:</strong> {entityData?.Category?.name || ''}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
                            </Grid>


                        </Grid>

                    </Grid>
                </CardContent>
            </Card>






            <div style={{ height: '500px', width: '99%', marginTop: '20px', marginLeft: '16px' }}>
                <DataGrid
                    rowCount={totalCount}
                    // apiRef={ref}
                    rows={videoData}
                    columns={columns}
                    disableRowSelectionOnClick
                    getRowHeight={() => 50}
                    columnHeaderHeight={64}
                    autoHeight
                    disableColumnMenu
                    disableColumnSelector
                    pageSizeOptions={[10, 25, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    onRowClick={(params: any, event: any) => {
                        setSelectedRow(params.row);
                        // Open your modal here
                        handleAddVideoClick();
                    }}
                    onPaginationModelChange={(model, details) => {
                        let data = Object.assign({}, JSON.parse(JSON.stringify(model)));
                        data.searchData = paginationModel.searchData;
                        setPaginationModel(data);
                    }}
                    paginationMode="server"
                    showColumnVerticalBorder={true}
                    showCellVerticalBorder={true}
                    loading={isLoading}
                />
            </div>
        </Grid>


    );
};

export default AddVideo;