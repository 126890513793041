import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { useAuth } from '../../../hooks/check-auth';
import useForm from "../../../hooks/useForm";

import { Avatar, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Link, Switch, Tooltip, Typography } from "@mui/material";

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'

import BranchesAPI from '../../../apis/branches.api'
import EntityAPI from "../../../apis/entity.api";

import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';

import AddBranchModal from "./addBranchModal";

type FormData = {
    name: string,
    email: string
    contactNumber: string,
    address: string,
    website: string,
};

const Branches = () => {

    const { id } = useParams();
    const { onShowSnackbar } = useAuth();
    const defaultValues: FormData = {
        name: '',
        email: '',
        contactNumber: '',
        address: '',
        website: '',
    };

    const { formValues, setFormValues, handleInputChange, errors, setErrors, handleReset } = useForm({ ...defaultValues });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState(0);
    const [branchesData, setBranchesData] = useState<GridRowsProp>([]);
    const [entityData, setEntityData] = useState<any | null>(null);
    const [reload, setReload] = useState<boolean>(false);

    const [openAddUpdateBranchModal, setOpenAddUpdateBranchModal] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    
    const getAllBranches = () => {
        setIsLoading(true);
        if(id){
            BranchesAPI.getAllBranches(id)
                .then((res) => {
                    setTotalCount(res.count);
                    setBranchesData(res.rows);
                })
                .catch((err) => console.log("err=====>", err.message))  // Fix the typo here (err.messsage to err.message)
                .finally(() => setIsLoading(false));
        }
    };

    const getEntityById = () => {
        setIsLoading(true);
        if(id){
            EntityAPI.getEntityById(id)
                .then((res) => {
                    setEntityData(res.data);
                })
                .catch((err) => console.log("err=====>", err.message))
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        getAllBranches();
    }, [reload]);

    useEffect(() => {
        getEntityById();
        getAllBranches();
    }, [id]);

    const removeEntityBranch = (id: string) => {
        setIsLoading(true);
        BranchesAPI
          .deleteBranch(id)
          .then((res) => {
            onShowSnackbar("Branch delete successfully", "success");
            getAllBranches();
            setOpenRemoveModal(false);
            setSelectedRow(null);  
          })
          .catch((err) => console.log("err=====>",err.messsage))
          .finally(() => {});
    };

    const columns: GridColDef[] = [
        {
            field: 'name', maxWidth: 420, sortable: false,
            filterable: false, headerName: 'Name', flex: 1
        },
        {
            field: 'email', maxWidth: 420, sortable: false,
            filterable: false, headerName: 'Email', flex: 1
        },
        {
            field: 'contactNumber', maxWidth: 420, sortable: false,
            filterable: false, headerName: 'Contact Number', flex: 1
        },
        {
            field: 'address', maxWidth: 420, sortable: false,
            filterable: false, headerName: 'Address', flex: 1
        },
        {
            field: 'website', maxWidth: 420, sortable: false,
            filterable: false, headerName: 'Website', flex: 1
        },
        {
            field: 'enabled',
            headerName: 'Action',
            flex: 1,
            maxWidth: 120,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip
                            title={"Delete"}
                        >
                            <DeleteIcon
                                style={{cursor: 'pointer', marginLeft: '5px'}}
                                color="error"
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setOpenRemoveModal(true);
                                    setSelectedRow(params.row);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        }
    ];

    const handleAddBranchClick = () => {
        setOpenAddUpdateBranchModal(true);
    };

    const handleCancel = () => {
        handleReset()
    };

    return (
        <Grid container spacing="16">
            <Button color="primary" size="medium" variant="contained" style={{ marginLeft: '90%', marginTop: '20px' }} onClick={handleAddBranchClick}>
                Add Branch
            </Button>
            {
                openAddUpdateBranchModal && (
                    <Dialog maxWidth="md" open={openAddUpdateBranchModal} onClose={handleCancel}>
                        <DialogTitle>{selectedRow ? 'UPDATE BRANCH' : 'ADD BRANCH'}</DialogTitle>
                        <DialogContent>
                            <AddBranchModal
                                entityId={id}
                                branchId={selectedRow ? selectedRow['id'] || "" : ""}
                                initialValue={{
                                    name: selectedRow ? selectedRow["name"] || "" : "",
                                    email: selectedRow ? selectedRow["email"] || "" : "",
                                    contactNumber: selectedRow ? selectedRow["contactNumber"] || "" : "",
                                    address: selectedRow ? selectedRow["address"] || "" : "",
                                    website: selectedRow ? selectedRow["website"] || "" : "",
                                }}
                                onCancel={() => {
                                    setOpenAddUpdateBranchModal(false)
                                    setSelectedRow(null);
                                }}
                                onSuccess={() => {
                                    setOpenAddUpdateBranchModal(false);
                                    setSelectedRow(null);
                                }}
                                getAllBranches={getAllBranches}
                            />
                        </DialogContent>
                    </Dialog>
                )
            }

            <Card style={{ marginLeft: '15px', marginTop: '20px', width: '100%' }}>
                <CardContent>
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <div>
                                <img
                                    src={entityData?.bannerImage.url}
                                    alt="Banner Image"
                                    style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px' }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <Tooltip title={` ${entityData?.companyName || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Company Name:</strong> {entityData?.companyName || ''}
                                        </Typography>
                                    </Tooltip>

                                    <Tooltip title={` ${entityData?.email || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px', }} variant="body1">
                                            <strong>Email:</strong> {entityData?.email || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.websiteLink || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Website Link:</strong> {entityData?.websiteLink || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.description || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Description:</strong> {entityData?.description || ''}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>

                                    <Tooltip title={` ${entityData?.contactNumber || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Contact Number:</strong> {entityData?.contactNumber || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.address || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Address:</strong> {entityData?.address || ''}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={` ${entityData?.Category?.name || ''}`} className="flexfull displayEllipsis">
                                        <Typography style={{ fontSize: '16px', marginBottom: '12px' }} variant="body1">
                                            <strong>Category:</strong> {entityData?.Category?.name || ''}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <div style={{ height: '500px', width: '99%', marginTop: '20px', marginLeft: '16px' }}>
                <DataGrid
                    rowCount={totalCount}
                    // apiRef={ref}
                    rows={branchesData}
                    columns={columns}
                    disableRowSelectionOnClick
                    getRowHeight={() => 50}
                    columnHeaderHeight={64}
                    autoHeight
                    disableColumnMenu
                    disableColumnSelector
                    pageSizeOptions={[10, 25, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    onRowClick={(params: any, event: any) => {
                        setSelectedRow(params.row);
                        // Open your modal here
                        handleAddBranchClick();
                    }}
                    paginationMode="client"
                    showColumnVerticalBorder={true}
                    showCellVerticalBorder={true}
                    loading={isLoading}
                />
            </div>

            {
                openRemoveModal && selectedRow && (
                    <Dialog
                        maxWidth="md"
                        open={openRemoveModal}
                    >
                        <DialogTitle>Remove Branch</DialogTitle>
                        <DialogContent dividers>
                            <Typography variant='body1'>
                                Are you sure you want to remove branch?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                size='small' variant='outlined'
                                onClick={() => {
                                    setOpenRemoveModal(false);
                                    setSelectedRow(null);  
                                }}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button 
                                size='small' variant='contained'
                                onClick={() => {
                                    removeEntityBranch(selectedRow.id);
                                }}
                            >
                                Remove
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </Grid>
    )

};

export default Branches;