import { useState, useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography"
import useForm from "../../hooks/useForm";
import { useAuth } from '../../hooks/check-auth';
import CategoryApi from "../../apis/category.api";
import EntityApi from "../../apis/entity.api";
import S3uploadApi from "../../apis/s3upload.api";

type FormData = {

    contactNumber: string,
    websiteLink: string,
    companyName: string,
    address: string,
    Category: string,
    description: string,
    bannerImage: any,
    email: string

};

type ComponentProps = {
    entityId?: any,
    initialValue?: FormData,
    onSuccess?: () => void
    onCancel?: () => void
    setOpen?: (value: boolean) => void;
};

const AddEntityManagement = (props: ComponentProps) => {
    const { onShowSnackbar } = useAuth();
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const defaultValues: FormData = {
        companyName: '',
        contactNumber: '',
        address: '',
        description: '',
        websiteLink: '',
        Category: '',
        bannerImage: { file: null, url: null },
        email: ''

    };

    const { formValues, setFormValues, handleInputChange, errors, setErrors, handleReset } = useForm({ ...defaultValues });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [categoryOptions, setCategoryOptions] = useState<{ id: string; name: string }[]>([]);

    useEffect(() => {
        if (props.initialValue) {
            const bannerImage = {
                path: props.initialValue.bannerImage?.path || null,
                url: props.initialValue.bannerImage?.url || null,
            };

            let data = Object.assign({}, JSON.parse(JSON.stringify(props.initialValue)));
            data.bannerImage = bannerImage;


            setFormValues(data);
            setImagePreview(props?.initialValue?.bannerImage?.url || null);
        }
    }, [props.initialValue]);


    const validate = () => {

        const formErrors: FormData = {} as FormData
        formErrors.description = formValues.description?.length > 1000 ? "Description Cannot be more than 1000 characters" : '';
        formErrors.websiteLink = "";
        formErrors.companyName = formValues.companyName.trim() === '' ? 'Company Name is required' : formValues.companyName.length > 250 ? ' Name cannot be more than 250 characters' : '';
        if (formValues.contactNumber === '') {
            formErrors.contactNumber = 'Contact number is required';
        } else if (formValues.contactNumber.length < 6 || formValues.contactNumber.length > 15) {
            formErrors.contactNumber = 'Contact number must be between 6 and 15 characters';
        } else {
            formErrors.contactNumber = ""
        }
        if (!formValues.Category || formValues.Category.length === 0) {
            formErrors.Category = "Category is required";
        } else {
            formErrors.Category = "";
        }
        if (formValues.bannerImage.file == null && !imagePreview) {
            formErrors.bannerImage = "Banner Image is required";
        } else {
            formErrors.bannerImage = "";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formValues.email && !emailRegex.test(formValues.email)) {
            formErrors.email = 'Invalid email';
        } else {
            formErrors.email = '';
        }

        setErrors({
            ...formErrors
        })

        return !Object.values(formErrors).some(val => val !== '')
    };

    const handleCancel = () => {
        handleReset()
        props.onCancel?.()
    };
    const createEntity = (data: any) => {
        setIsLoading(true);

        EntityApi.createEntity(data, formValues.Category.id)
            .then((res) => {
                props?.onSuccess?.();
                props?.setOpen?.(false);
                onShowSnackbar("Entity added successfully", "success");
            })
            .catch((err) => {
                if (err.response && err.response.status === 400) {

                    onShowSnackbar("Duplicate Entity Exists", "error");
                } else {
                    onShowSnackbar("Error while adding entity", "error");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateEntity = (entityId: string, data: any) => {
        setIsLoading(true);
        EntityApi.updateEntity(
            entityId, data)
            .then((res) => {
                props?.onSuccess?.()
                onShowSnackbar("Entity details updated successfully", "success");
            })
            .catch((err) => {
                setError(err.message);
                onShowSnackbar("Error while updating the entity", "error");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (validate()) {
            setIsLoading(true);

            try {
                const file = formValues.bannerImage.file;
                if (props.entityId) {
                    if (formValues.bannerImage.file) {
                        const getUploadsIconParams = {
                            fileName: file.name,
                            fileType: file.type.split("/")[1],
                            fileSize: file.size,
                            path: 'post',
                        };
                        const res = await S3uploadApi.uploadImage(getUploadsIconParams);
                        const signedURLRes = res;

                        await S3uploadApi.S3UploadRequest(signedURLRes.urls, file);

                        const params = {
                            bannerImage: signedURLRes.path,
                            companyName: formValues.companyName,
                            address: formValues.address,
                            contactNumber: formValues.contactNumber,
                            description: formValues.description,
                            websiteLink: formValues.websiteLink,
                            email: formValues.email,
                            CategoryId: formValues.categoryId

                        };
                        updateEntity(props?.entityId, params);
                    } else {
                        const params = {
                            bannerImage: props.initialValue?.bannerImage.path,
                            companyName: formValues.companyName,
                            address: formValues.address,
                            contactNumber: formValues.contactNumber,
                            description: formValues.description,
                            websiteLink: formValues.websiteLink,
                            email: formValues.email,
                            CategoryId: formValues.categoryId
                        };
                        updateEntity(props?.entityId, params);
                    }
                } else {
                    const params = {
                        bannerImage: props.initialValue?.bannerImage.path,
                        companyName: formValues.companyName,
                        address: formValues.address,
                        contactNumber: formValues.contactNumber,
                        description: formValues.description,
                        websiteLink: formValues.websiteLink,
                        email: formValues.email,
                    };


                    if (formValues.bannerImage.file) {
                        const getUploadsIconParams = {
                            fileName: file.name,
                            fileType: file.type.split("/")[1],
                            fileSize: file.size,
                            path: 'post',
                        };
                        const res = await S3uploadApi.uploadImage(getUploadsIconParams);
                        const signedURLRes = res;

                        await S3uploadApi.S3UploadRequest(signedURLRes.urls, file);

                        params.bannerImage = signedURLRes.path;
                    }

                    createEntity(params);
                }


            } catch (err) {
                console.error(err);
                // setError(err.message);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const getAllCategories = (data?: any) => {
        CategoryApi.getAllCategory(data)
            .then((res) => {
                const mappedResponse = res.rows.map((item: any) => ({
                    id: item.id,
                    name: item.name
                }));
                setCategoryOptions(mappedResponse);
            })
            .catch((err) => {
                console.error("Error fetching categories:", err);

            })
            .finally(() => setIsLoading(false));
    };
    useEffect(() => {
        getAllCategories()
    }, []);


    const handleAutocompleteChange = (elementName: string, newVal: any | null) => {
        setFormValues({
            ...formValues,
            [elementName]: newVal ? newVal : null,
            categoryId: newVal ? newVal.id : '',
        });
    }


    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                setImagePreview(reader.result as string);
                setFormValues((prevValues: any) => ({
                    ...prevValues,
                    bannerImage: { file, url: reader.result },
                }));
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing="16">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '7px' }}>
                    <TextField
                        required
                        fullWidth
                        id="title-input"
                        name="companyName"
                        label="Company Name"
                        type="text"
                        value={formValues.companyName}
                        onChange={handleInputChange}
                        error={!!errors.companyName}
                        helperText={errors.companyName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="title-input"
                        name="contactNumber"
                        label="Contact Number"
                        type="tel"
                        value={formValues.contactNumber}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            // Remove non-numeric characters except '+'
                            const numericValue = inputValue.replace(/[^0-9+]/g, '');
                            handleInputChange({ target: { name: 'contactNumber', value: numericValue } });
                        }}
                        inputProps={{
                            maxLength: 15,
                            inputMode: 'tel',
                        }}
                        error={!!errors.contactNumber}
                        helperText={errors.contactNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        fullWidth
                        id="title-input"
                        name="email"
                        label="Email"
                        type="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        fullWidth
                        id="shortDescription-input"
                        name="address"
                        label="Address"
                        type="text"
                        value={formValues.address}
                        onChange={handleInputChange}
                        error={!!errors.address}
                        helperText={errors.address}
                        multiline
                        minRows={2}
                        maxRows={5}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        fullWidth
                        id="url-input"
                        name="websiteLink"
                        label="Website Link"
                        type="text"
                        value={formValues.websiteLink}
                        onChange={handleInputChange}
                        error={!!errors.websiteLink}
                        helperText={errors.websiteLink}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Autocomplete
                        fullWidth
                        id="combo-box-demo0"
                        options={categoryOptions}
                        getOptionLabel={(option) => option?.name ? option.name : ""}
                        value={formValues.Category}
                        onChange={(e, value) => handleAutocompleteChange("Category", value)}
                        renderInput={(params) => (
                            <TextField
                                autoFocus={false}
                                required
                                {...params}
                                label={'Category'}
                                name="Category"
                                value={formValues.Category?.name}
                                error={!!errors.Category}
                                helperText={errors.Category}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                        // required
                        fullWidth
                        id="shortDescription-input"
                        name="description"
                        label="Description"
                        type="text"
                        value={formValues.description}
                        onChange={handleInputChange}
                        error={!!errors.description}
                        helperText={errors.description}
                        multiline
                        minRows={2}
                        maxRows={5}
                    />
                </Grid>
                <Typography style={{ marginLeft: '20px', padding: '10px' }}>Banner Image *</Typography>

                <Grid item xs={12}>
                    <label htmlFor="file-input" className="file-label">
                        <div>
                            {imagePreview && (
                                <div style={{ marginTop: 10 }}>
                                    <img
                                        src={imagePreview}
                                        alt="Selected Icon"
                                        style={{ width: 100, height: 100 }}
                                    />
                                </div>
                            )}
                            <input
                                id="contained-button-file"
                                name="bannerImage"
                                className="file-input"
                                accept="image/*"
                                type="file"
                                onChange={handleFileInputChange}
                                onBlur={validate}
                            />

                        </div>
                    </label>
                    {errors.bannerImage && (
                        <FormHelperText error>{errors.bannerImage}</FormHelperText>
                    )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" style={{ marginLeft: '10px', float: 'right' }}>
                                {props.entityId ? "Save" : "ADD"}
                            </Button>
                            <Button variant="outlined" color="primary" type="button" onClick={handleCancel} style={{ marginLeft: '10px', float: 'right' }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

};

export default AddEntityManagement;