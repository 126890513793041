import { useState, useEffect, useRef } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { GridRowsProp, useGridApiRef } from "@mui/x-data-grid";
import ModalComponent from "../Common/ModalComponent";
import AddEntityManagement from './addEntityManagement';
import EntityManagementTable from './EntityManagementTable';
import { TextField } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import MultiselectFilter from "../Common/MultiSelectFilter";
import AddVideo from './Video/addVideo';
import CategoryApi from '../../apis/category.api';
import EntityApi from '../../apis/entity.api';

const EntityManagementView = () => {
    const dataGridRef = useGridApiRef<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [openVideo, setOpenVideo] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [dataForEdit, setDataForEdit] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState<{ name: string, id: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userData, setUserData] = useState<GridRowsProp>([]);
    const [totalCount, setTotalCount] = useState(0);
    const initiaload = useRef(true)


    const defaultSearchData = useRef({
        selectedValue: [],
        searchTerm: ''
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
        searchData: {
            ...defaultSearchData.current
        }
    });

    useEffect(() => {
        if (isRefresh) {
            setTimeout(function () {
                setIsRefresh(false);
            }, 5000)
        }
    }, [isRefresh]);


    const getAllCategories = () => {
        setIsLoading(true);
        CategoryApi.getAllCategory()
            .then((res) => {
                setCategoryOptions(res.rows);
            })
            .catch((err) => {
                console.error("Error fetching categories:", err);
            })
            .finally(() => setIsLoading(false));
    };

    const getAllEntityManagement = () => {
        setIsLoading(true);
        EntityApi
            .getAllEntity(paginationModel)
            .then((res) => {
                setTotalCount(res.count);
                setUserData(res.rows);
            })
            .catch((err) => console.log("err=====>", err.message))
            .finally(() => setIsLoading(false));
    };

    const handleSearch = (e: any) => {
        const val = e.target.value;
        setSearchTerm(val);
    };

    useEffect(() => {
        getAllCategories();
    }, [])

    useEffect(() => {
        getAllEntityManagement();
    }, [paginationModel]);




    useEffect(() => {
        if (initiaload.current) {
            initiaload.current = false;
        } else {
            const timer = setTimeout(() => {
                handleChangeFilter("searchTerm", searchTerm)

            }, 1000);
            return () => clearTimeout(timer);

        }

    }, [searchTerm]);

    const handleChangeFilter = (elementName: string, value: any) => {
        const data = Object.assign({}, JSON.parse(JSON.stringify(paginationModel)));

        data.searchData[elementName] = value;

        data.page = 0;
        data.pageSize = 10;
        setPaginationModel(data);
    };

    const handleCategoryFilterChange = (selectedValues: []) => {
        handleChangeFilter("selectedValue", selectedValues)

    };



    const ActionButtons = (
        <>
            <Stack direction="row" style={{ columnGap: '10px' }}>
                <Stack direction="row" spacing={2} sx={{ marginLeft: 'auto' }}>
                    <Button
                        size="medium" variant="contained"
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        Add Entity
                    </Button>
                </Stack>
            </Stack>
            <Stack direction="row">
                <Stack direction="row" spacing={5} >
                    <TextField
                        className="sizeSmall"
                        size="small"
                        style={{ width: '250px', marginTop: '-60px' }}
                        InputProps={{
                            startAdornment: <SearchOutlined />,
                        }}
                        label={undefined}
                        placeholder="Search by company name"
                        onChange={handleSearch}
                        variant="outlined"
                        value={searchTerm}
                    />
                </Stack>
                <div style={{ marginLeft: '50px', marginTop: '-55px' }}>
                    <MultiselectFilter
                        arrayList={categoryOptions ? categoryOptions : ''}
                        filterName="Filter By Category"
                        title="Filter By Category"
                        filterOn="id"
                        saveButtonText="Apply"
                        value={paginationModel.searchData.selectedValue}
                        onChangeFilter={handleCategoryFilterChange}
                        clearButtonText="Clear"

                    />
                </div>
            </Stack>
        </>
    );

    return (
        <>
            <ModalComponent
                open={open} setOpen={setOpen}
                title={dataForEdit ? "Update Entity" : "Add Entity"}
            >
                <AddEntityManagement
                    entityId={dataForEdit ? dataForEdit["id"] || "" : ""}
                    initialValue={{
                        companyName: dataForEdit ? dataForEdit["companyName"] || "" : "",
                        contactNumber: dataForEdit ? dataForEdit['contactNumber'] || "" : '',
                        description: dataForEdit ? dataForEdit["description"] || "" : "",
                        address: dataForEdit ? dataForEdit["address"] || "" : "",
                        websiteLink: dataForEdit ? dataForEdit["websiteLink"] || "" : "",
                        Category: dataForEdit ? dataForEdit["Category"] || "" : "",
                        bannerImage: dataForEdit ? dataForEdit["bannerImage"] || { file: null, url: '' } : { file: null, url: '' },
                        email: dataForEdit ? dataForEdit["email"] || "" : "",
                    }}
                    onCancel={() => {
                        setOpen(false);
                        setDataForEdit(null);
                    }}
                    onSuccess={() => {
                        setIsRefresh(true);
                        setDataForEdit(null);
                    }}
                    setOpen={setOpen}
                    
                />
            </ModalComponent>
            {openVideo &&
                <ModalComponent
                    open={openVideo} setOpen={setOpenVideo}
                    title={dataForEdit ? "Update Video" : "Add Video"}
                >
                    <AddVideo
                        postId={dataForEdit ? dataForEdit["id"] || "" : ""}
                        initialValue={{
                            title: dataForEdit ? dataForEdit["title"] || "" : "",
                            enable: dataForEdit ? dataForEdit["enable"] || true : true,
                            link: dataForEdit ? dataForEdit["link"] || "" : ""

                        }}
                        onCancel={() => {
                            setOpenVideo(false);
                            setDataForEdit(null);
                        }}
                        onSuccess={() => {
                            setOpenVideo(false);
                            setIsRefresh(true);
                            setDataForEdit(null);
                        }}
                    />

                </ModalComponent>
            }
            <Stack direction="column" spacing={2}>
                {ActionButtons}
                <EntityManagementTable
                    onEditUser={(data: any) => {
                        const updateData = Object.assign({}, data);
                        setDataForEdit(updateData);
                        setOpen(true);
                        setOpenVideo(false)

                    }}
                    onSuccess={() => {
                        setOpen(false);
                        setIsRefresh(true);
                        setDataForEdit(null);


                    }}
                    totalCount={totalCount}
                    userData={userData}
                    ref={dataGridRef}
                    isRefresh={isRefresh}
                    setOpenVideo={setOpenVideo}
                    // setIsRefresh={() => setIsRefresh(false)}
                    getAllEntityManagement={getAllEntityManagement}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                />
            </Stack>
        </>
    )

};

export default EntityManagementView;

